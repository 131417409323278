import React from "react"
import styled from "styled-components"
import { Paragraph } from "./Paragraph"

const PricingTableSection = styled.section`
  margin-top: 2rem;
  margin-bottom: 4rem;

  width: calc(100% - 2.5rem);
  margin-inline: auto;

  @media (min-width: 64em) {
    margin-top: 2rem;
    margin-bottom: 7.5rem;
  }
`

const Table = styled.table`
  max-width: 800px;
  width: 100%;
  margin-inline: auto;
  min-width: 280px;
  border-collapse: collapse;
  z-index: 2;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  table-layout: fixed;
  font-family: var(--ff-primary);
  @media (min-width: 40em) {
  }

  thead {
    height: 48px;
    font-family: var(--ff-secondary);
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 28px;
    color: var(--clr-text-blue);
    border-bottom: 4px solid #e00038;
    th {
      text-align: center;
    }

    @media (min-width: 40em) {
      height: 75px;
      border-bottom: 5px solid #e00038;
      font-size: 1.5rem;
      line-height: 23px;
    }
  }
  tbody {
    width: 100%;
    td,
    thead th {
      overflow: hidden;
      position: relative;
    }
    tr:last-child {
    }
  }
  .border-bottom {
  }
  tr {
    height: 48px;
    @media (min-width: 40em) {
      height: 75px;
    }
  }
  td {
    text-align: center;

    font-family: var(--ff-secondary);
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;

    &:first-child {
      font-style: normal;
      font-weight: 700;
    }

    tr:last-child td {
      background: transparent;
      border: 0px;
    }

    @media (min-width: 40em) {
      font-size: 1.5rem;
      line-height: 28px;
    }
  }
`

const PricingTable = ({ thHeader }) => {
  return (
    <>
      <Paragraph>
        We’re proud to offer simple, transparent pricing, with no hidden fees.
        Note that actual quoted rates will depend on project details and talent,
        and these will be formally presented to you ahead of any commitment.
      </Paragraph>
      <PricingTableSection>
        <Table>
          <thead>
            <tr>
              <th>{thHeader}</th>
              <th>Senior €/h</th>
              <th>Lead €/h</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Software Engineering</td>
              <td>90-100</td>
              <td>100-120</td>
            </tr>
            <tr>
              <td>Data & AI</td>
              <td>90-100</td>
              <td>100-130</td>
            </tr>
            <tr>
              <td>Cloud & Devops</td>
              <td>90-100</td>
              <td>100-130</td>
            </tr>
            <tr>
              <td>Test automation</td>
              <td>80-90</td>
              <td>90-100</td>
            </tr>
            <tr>
              <td>Business Agility</td>
              <td>90-100</td>
              <td>100-140</td>
            </tr>
            <tr>
              <td>Design</td>
              <td>90-100</td>
              <td>100-120</td>
            </tr>
          </tbody>
        </Table>
      </PricingTableSection>
    </>
  )
}

export default PricingTable
