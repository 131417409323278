import styled from "styled-components"

export const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 0.875em;
  line-height: 21px;
  letter-spacing: 0.0015em;
  max-width: calc(100% - 2.5rem);
  margin-inline: auto;

  @media (min-width: 40em) {
    font-size: 1em;
    line-height: 24px;
  }

  @media (min-width: 840px) {
    max-width: 800px;
  }
`
