import React from "react"
import Layout from "../../components/common/Layout"
import Main from "../../components/common/Main"
import Meta from "../../components/common/Meta"
import { FindingRightConsultant } from "../../components/common/downloadSection/FindingRightConsultant"
import ContactBox from "../../components/common/formboxes/ContactBox"
import PricingTable from "../../components/our-pricing/PricingTable"
import PageIntro from "../../components/common/intro/PageIntro"
import InfoBoxes from "../../components/common/infoboxes/InfoBoxes"
import PricingNavigationBar from "../../components/our-pricing/PricingNavigationBar"

export const Head = () => (
  <Meta
    title="Rebase Pricing - Affordable Dev, Cloud, QA, and Agile Services"
    description="View Rebase's competitive pricing for custom software development, cloud services, data engineering, test automation, business agility and more."
  />
)

const Rates = () => {
  const title = [["Discover", "Our Prices"]]

  return (
    <Layout>
      <Main>
        <PageIntro title={title} subheading="" />
        <PricingNavigationBar />

        <PricingTable thHeader="Expertise" />
        <FindingRightConsultant dark />
        <InfoBoxes
          content={[
            { number: 7.8, extra: "", content: "MEUR Net sales (2023)" },
            { number: 11, extra: "%", content: "EBITA (2023)" },
            { number: 65, extra: "%", content: "Growth (2023)" },
          ]}
        />
      </Main>
      <ContactBox />
    </Layout>
  )
}

export default Rates
