import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"

const NavigationBarContainer = styled.div`
  z-index: 1;
  background: white;
  top: 74px;
  display: flex;
  justify-content: start;

  margin-left: auto;
  margin-right: auto;
  max-width: calc(100% - 2.5rem);

  margin-bottom: 2rem;
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 40em) {
    top: 70px;
    margin-top: 4rem;
    justify-content: start;
    margin-top: 3rem;
  }

  @media (min-width: 840px) {
    max-width: 800px;
  }
`

const NavigationBar = styled.nav`
  display: flex;
  justify-content: center;
`

const MenuItem = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  color: var(--clr-text-blue);
  justify-content: center;
  align-items: center;

  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 20px;

  text-align: center;

  min-width: 131px;
  min-height: 50px;
  width: 150px;
  height: 50px;
  border-bottom: 4px solid var(--clr-primary);
  @media (min-width: 40em) {
    font-size: 1.5em;
    line-height: 32px;

    width: 230px;
    height: 90px;
    min-width: 190px;
    min-height: 90px;
    border-width: 8px;
  }

  &:hover {
    color: var(--clr-secondary-dark);
  }
`

const PricingNavigationBar = () => {
  const location = useLocation()
  const paths = ["/pricing/calculator", "/pricing/rates"]

  const getActiveStyle = path => {
    return location.pathname.includes(path)
      ? {
          color: "var(--clr-secondary-dark)",
          borderColor: "var(--clr-secondary-dark)",
        }
      : {}
  }

  return (
    <NavigationBarContainer>
      <NavigationBar>
        {paths.map((path, index) => (
          <MenuItem to={path} style={getActiveStyle(path)} key={index}>
            {path === "/pricing/calculator" ? "Project Calculator" : "Rates"}
          </MenuItem>
        ))}
      </NavigationBar>
    </NavigationBarContainer>
  )
}

export default PricingNavigationBar
